// src/UrlParamsContext.js
import React, { createContext, useContext, useState } from 'react';

const UrlParamsContext = createContext();

export const useUrlParams = () => {
    return useContext(UrlParamsContext);
};

export const UrlParamsProvider = ({ children }) => {
    const [params, setParams] = useState({});

    return (
        <UrlParamsContext.Provider value={{ params, setParams }}>
            {children}
        </UrlParamsContext.Provider>
    );
};
