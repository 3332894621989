export function trimChar(string, charToRemove='@') {
    while(string.charAt(0)==charToRemove) {
        string = string.substring(1);
    }

    while(string.charAt(string.length-1)==charToRemove) {
        string = string.substring(0,string.length-1);
    }

    return string;
}

export function hapticSend(){
    window.TelegramWebviewProxy.postEvent('web_app_trigger_haptic_feedback',
        JSON.stringify( {type:"notification",
            impact_style:"heavy",
            notification_type:"success"}))
}

export function sendTgMessage(username, message){
    window.TelegramWebviewProxy.postEvent('web_app_open_tg_link',
        JSON.stringify( {"path_full":`/${username}?text=${message}`}))
}