// src/components/RoomList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import './RoomList.css';
import WeekCalendar from './WeekCalendar';
import {BaseUrl} from '../config';

const RoomList = () => {
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(null);

    useEffect(() => {
        axios.get(BaseUrl+'/api/rooms')
            .then(response => setRooms(response.data))
            .catch(error => console.error('Error fetching rooms:', error));
    }, []);

    return (
        <div className="container">
            <div className="room-list">
                <h1>Переговорки</h1>
                <ul>
                    {rooms.map(room => (
                        <li key={room.id} onClick={() => setSelectedRoom(room)}>
                            {room.name} - {room.capacity} people
                        </li>
                    ))}
                </ul>
            </div>
            <div className="calendar">
                {selectedRoom && (
                    <>
                        <h2>Расписание для {selectedRoom.name}</h2>
                        <WeekCalendar room={selectedRoom} />
                    </>
                )}
            </div>
        </div>
    );
};

export default RoomList;
