// src/App.js
import React, { useEffect, useState } from 'react';
import RoomList from './components/RoomList';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import WeekCalendar from './components/WeekCalendar';
import {tg_user} from './config';
import {UrlParamsProvider, useUrlParams} from "./components/UrlParamsContext";
import { socket } from './components/socket';

const App = () => {
    // const user= tg_user ? tg_user: params.username;

    const [selectedRoom, setSelectedRoom] = useState(null);
    // window.Telegram.WebApp.isVerticalSwipesEnabled=false;
    window.Telegram.WebApp.ready()
    window.Telegram.WebApp.expand()
    window.Telegram.WebApp.disableVerticalSwipes();
    if (window.TelegramWebviewProxy)
    {window.TelegramWebviewProxy.postEvent('web_app_trigger_haptic_feedback', {"type":"notification","impact_style":"heavy"})}

    const [isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('booking_created', (booking) => { console.log(booking); });
        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('booking_created', onDisconnect);
        };
    }, []);
    return (
        <Router>
            <UrlParamsProvider>
                <UrlParamsHandler>
                    <div className="App">
                        {window.Telegram.WebApp&&tg_user ? (
                            <>
                                <header className="App-header">
                                    <h1>Бронирование</h1>
                                </header>
                                <main>
                                    <RoomList onSelectRoom={setSelectedRoom} />
                                    <Routes>
                                        <Route path="/calendar" element={<WeekCalendar room={selectedRoom} />} />
                                        {/* другие маршруты */}
                                    </Routes>
                                </main>
                            </>
                        ) : (
                            <div>
                                <header className="App-header">
                                    <h1>Доступно только из ТГ</h1>
                                </header>
                            </div>
                        )}
                    </div>
                </UrlParamsHandler>
            </UrlParamsProvider>
        </Router>
    );
};

const UrlParamsHandler = ({ children }) => {
    const location = useLocation();
    const { setParams } = useUrlParams();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(searchParams.entries());
        setParams(params);
    }, [location, setParams]);

    return children;
};


export default App;
