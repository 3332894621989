// src/components/BookingModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import './BookingModal.css';
import {BaseUrl, tg_user} from '../config';
import {useUrlParams} from "./UrlParamsContext";

Modal.setAppElement('#root'); // Установите элемент приложения
const WebApp = window.Telegram.WebApp
const BookingModal = ({ isOpen, onRequestClose, room, selectedSlot, onBookingComplete }) => {
    const { params } = useUrlParams();
    const user= tg_user ? tg_user: params.username;
    const [name, setName] = useState(`Бронирование ${user}`);
    const [duration, setDuration] = useState(60);
    const [startTime, setStartTime] = useState('');


    useEffect(() => {
        if (selectedSlot) {
            setStartTime(selectedSlot.start.toTimeString().split(' ')[0]);
        }
    }, [selectedSlot]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            room_id: room.id,
            name,
            date: selectedSlot.start.toISOString().split('T')[0],
            time: startTime,
            author: user,
            duration,
        }
        console.log(selectedSlot.start.toISOString().split('T')[0],)
        axios.post(BaseUrl+'/api/book', data)
            .then(response => {
                WebApp.showPopup({title:"Забронировано", message:`Ваша бронь: \n${room.name} \n ${ new Date(data.date).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })} - ${data.time}`});
                // onBookingComplete();
                onRequestClose();
            })
            .catch(error => {
                console.error('Error booking room:', error);
                WebApp.showAlert(error.response.data.detail);
            });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Booking Modal"
            className="Modal"
            overlayClassName="Overlay"
        >
            <h2>Забронировать {room.name}</h2>
            <h3>{selectedSlot && selectedSlot.start.toLocaleDateString('ru-RU')}</h3>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Название встречи:</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div>
                    <label>Начало:</label>
                    <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} required />
                </div>
                <div>
                    <label>Продолжительность (минут):</label>
                    <input type="number" value={duration} onChange={(e) => setDuration(parseInt(e.target.value))} required />
                </div>
                <button type="submit">Забронировать</button>
                <button type="button" onClick={onRequestClose}>Отмена</button>
            </form>
        </Modal>
    );
};

export default BookingModal;
