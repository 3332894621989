// src/components/WeekCalendar.js
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import './WeekCalendar.css';
import BookingModal from './BookingModal';
import 'moment/locale/ru';
import { BaseUrl, tg_user } from '../config';
import { useUrlParams } from './UrlParamsContext';
import { socket } from './socket';
import { hapticSend, sendTgMessage, trimChar } from '../utils';
import { useSwipeable } from 'react-swipeable';

const localizer = momentLocalizer(moment);
const WebApp = window.Telegram.WebApp;

const WeekCalendar = ({ room }) => {
    const calendarRef = React.useRef(null);
    const { params } = useUrlParams();
    const token = params.token;
    const role = params.role;
    const [events, setEvents] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isLongTap, setIsLongTap] = useState(false);
    const [touchTimeout, setTouchTimeout] = useState(null);
    const [animating, setAnimating] = useState(false);
    const [animationDirection, setAnimationDirection] = useState('');
    const user = tg_user ? tg_user : params.username;

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        if (room) {
            fetchBookings();
            socket.on('booking_created', (booking) => {
                if (booking.room_id !== room.id) return;
                setEvents((prevEvents) => [
                    ...prevEvents,
                    {
                        id: booking.id,
                        title: `${booking.name} - ${booking.author.fio}`,
                        start: new Date(`${booking.date}T${booking.time}`),
                        end: new Date(moment(`${booking.date}T${booking.time}`).add(booking.duration, 'minutes').toISOString()),
                        author: booking.author
                    }
                ]);
                hapticSend();
            });

            socket.on('booking_deleted', (response) => {
                setEvents((prevEvents) => prevEvents.filter(event => event.id !== parseInt(response.booking_id)));
                hapticSend();
            });
        }

        return () => {
            socket.off('booking_created');
            socket.off('booking_deleted');
            window.removeEventListener('resize', handleResize);
        };
    }, [room]);

    const fetchBookings = () => {
        if (room) {
            axios.get(`${BaseUrl}/api/bookings/${room.id}`)
                .then(response => {
                    const bookings = response.data.map(booking => ({
                        id: booking.id,
                        title: `${booking.name} - ${booking.author.fio}`,
                        start: new Date(`${booking.date}T${booking.time}`),
                        end: new Date(moment(`${booking.date}T${booking.time}`).add(booking.duration, 'minutes').toISOString()),
                        author: booking.author
                    }));
                    setEvents(bookings);
                })
                .catch(error => console.error('Error fetching bookings:', error));
        }
    };

    const handleTouchStart = (slotInfo) => {
        const timeout = setTimeout(() => {
            setSelectedSlot({ start: slotInfo.start, end: slotInfo.end });
            setIsModalOpen(true);
            hapticSend();
        }, 200);
        setTouchTimeout(timeout);
    };

    const handleTouchMove = () => {
        clearTimeout(touchTimeout);
    };

    const handleTouchEnd = () => {
        setIsLongTap(false);
    };

    const handleSwipedLeft = () => {
        setAnimationDirection('left');
        setAnimating(true);
        setTimeout(() => {
            calendarRef.current.handleNavigate("NEXT");
            setAnimating(false);
            setAnimationDirection('');
        }, 300); // duration of the animation
    };

    const handleSwipedRight = () => {
        setAnimationDirection('right');
        setAnimating(true);
        setTimeout(() => {
            calendarRef.current.handleNavigate("PREV");
            setAnimating(false);
            setAnimationDirection('');
        }, 300); // duration of the animation
    };


    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipedLeft,
        onSwipedRight: handleSwipedRight,
        trackMouse: true
    });

    const handleSelectSlot = (slotInfo) => {
        const today = new Date();
        if (slotInfo.start < today) {
            return;
        }

        handleTouchStart(slotInfo);
        hapticSend();
    };

    const handleBookingComplete = () => {
        fetchBookings();
    };

    const handleDeleteBooking = (eventId) => {
        axios.delete(`${BaseUrl}/api/bookings/${eventId}`, {
            headers: {
                'X-User-Name': user,
                'X-Token': token
            }
        })
            .then(response => {
                WebApp.showAlert(response.data.message);
            })
            .catch(error => {
                WebApp.showAlert(error.response.data.detail);
            });
    };

    const eventPropGetter = (event) => ({
        style: {
            backgroundColor: event.author.username === `@${user}` ? '#00cc66' : '#666699',
            color: 'white',
            borderRadius: '2px',
            border: 'solid #1f1f2e',
            display: 'block',
            padding: '1px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    });

    const onSelectEvent = (event) => {
        const start = localizer.format(event.start, 'HH:mm DD.MM');
        const message = `Добрый день! Вопрос по твоей брони: ${room.name} - ${start}`;
        if (trimChar(event.author.username) !== trimChar(user) && parseInt(role) === 1) {
            WebApp.showPopup(
                {
                    title: "Внимание",
                    message: "Выберите действие",
                    buttons: [
                        { id: 'create', text: "Создать", type: "default" },
                        { id: 'author', text: "Написать автору", type: "default" },
                        { id: 'delete', text: "Удалить встречу", type: "destructive" },
                    ]
                },
                function (button) {
                    switch (button) {
                        case 'create':
                            handleSelectSlot(event);
                            break;
                        case 'author':
                            sendTgMessage(trimChar(event.author.username, '@'), message);
                            break;
                        case 'delete':
                            handleDeleteBooking(event.id);
                            break;
                    }
                }
            );
        } else if (trimChar(event.author.username) === trimChar(user)) {
            WebApp.showConfirm(`Удалить бронь: ${event.title}?`, function (isOk) {
                if (isOk) {
                    handleDeleteBooking(event.id);
                }
            });
        } else {
            WebApp.showConfirm(`Написать автору ${event.author.fio || event.author.username}?`, function (isOk) {
                if (isOk) {
                    sendTgMessage(trimChar(event.author.username, '@'), message);
                }
            });
        }
    };

    const dayPropGetter = (date) => {
        const today = new Date();
        if (date < today) {
            return {
                className: 'rbc-day-past',
            };
        }
        return {};
    };

    const getVisibleDays = () => {
        const today = moment();
        const days = [
            today.clone().subtract(1, 'days').toDate(),
            today.toDate(),
            today.clone().add(1, 'days').toDate(),
        ];
        return days;
    };

    const formats = {
        timeGutterFormat: (date, culture, localizer) =>
            localizer.format(date, 'HH:mm', culture),
        eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
            localizer.format(start, 'HH:mm', culture) + ' - ' + localizer.format(end, 'HH:mm', culture),
        eventTimeFormat: (date, culture, localizer) =>
            localizer.format(date, 'HH:mm', culture),
    };

    return (
        <div className={`week-calendar ${animating ? 'animating' : ''} ${animationDirection}`}>
            <div {...swipeHandlers}>
                <Calendar
                    localizer={localizer}
                    selectable
                    events={events}
                    startAccessor="start"
                    min={new Date(new Date().setHours(8, 0, 0, 0))}
                    max={new Date(new Date().setHours(22, 0, 0, 0))}
                    endAccessor="end"
                    style={{height: 'auto', width: '100%'}}
                    views={['day', 'week', 'work_week']}
                    defaultView={Views.WORK_WEEK}
                    onSelecting={(range) => (range.end - range.start) <= 1800000 && range.start > new Date()}
                    onSelectSlot={handleSelectSlot}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    eventPropGetter={eventPropGetter}
                    slotPropGetter={dayPropGetter}
                    onSelectEvent={onSelectEvent}
                    formats={formats}
                    ref={calendarRef}
                    defaultDate={new Date()}
                    days={isMobile ? getVisibleDays() : undefined}
                    messages={{
                        next: "вперед",
                        previous: "назад",
                        today: "сегодня",
                        month: "Месяц",
                        week: "Неделя",
                        work_week: "Рабочая неделя",
                        day: "День"
                    }}
                />
                <BookingModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    room={room}
                    selectedSlot={selectedSlot}
                    onBookingComplete={handleBookingComplete}
                />
            </div>
        </div>
    );
};

export default WeekCalendar;
